import React, {useState,useEffect} from 'react';
import './navigation.css';
import {Link} from 'gatsby';
export default function Navigation({sections}) {

  const [currentSection,setCurrentSection] = useState(null);
  const [navigationUI,setNavigationUI] = useState([]);
  useEffect(() => {
    let navigationUIArray = [];
    sections.forEach((x,i) => {
      navigationUIArray.push(<Link key={i} to={x =='home' ? '/' : `/${x}/`} className='navigationLink' activeStyle={{color:'#ba94ff'}}>{x}</Link>)
    })
    setNavigationUI(navigationUIArray);
  },[])

  return(
    <div className='navigationConatiner'>
{navigationUI}
    </div>
  )
}
