import React from 'react';
import Navigation from '../navbar/navigation';
import './layout.css';
export default function Layout({children}){

  return(
    <div className='layoutDiv'>
    <Navigation sections={['home','content','contact']}  />
    {children}
    </div>
  )
}
